.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.tick-container {
  width: 80px;
  height: 80px;
  position: relative;
}

.tick-icon {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid #4caf50;
  position: relative;
  animation: circle-fill 0.4s ease-in-out 0.4s forwards;
  opacity: 0;
}

.tick-line {
  position: absolute;
  background-color: #4caf50;
  border-radius: 2px;
}

.tick-line1 {
  width: 25px;
  height: 3px;
  top: 46px;
  left: 21px;
  transform: rotate(45deg);
  transform-origin: 0% 50%;
  animation: tick-draw 0.3s ease-in-out 0.8s forwards;
  opacity: 0;
}

.tick-line2 {
  width: 40px;
  height: 3px;
  top: 39px;
  left: 34px;
  transform: rotate(-45deg);
  transform-origin: 0% 50%;
  animation: tick-draw 0.3s ease-in-out 0.9s forwards;
  opacity: 0;
}

@keyframes circle-fill {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes tick-draw {
  0% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scaleX(0) rotate(45deg);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 50%;
    transform: scaleX(1) rotate(45deg);
  }
}

.tick-line2 {
  animation-name: tick-draw-2;
}

@keyframes tick-draw-2 {
  0% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scaleX(0) rotate(-45deg);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 50%;
    transform: scaleX(1) rotate(-45deg);
  }
}
